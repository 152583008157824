<template>
    <img :src="image"  :alt="txt" class="mb-3"/>
</template>

<script>
export default {
  name: "AppImage",
  props: {
    width: Intl,
    height: Intl,
    txt: String
  },
  data() {
    return {
      img: "https://picsum.photos/"+this.width+"/"+this.height+"?random=4",
      image: "https://via.placeholder.com/"+this.width+"x"+this.height+".png?text="+this.txt
    }
  }
}
</script>

<style scoped>
img{
  width: 100%;
}
</style>