<template>
  <PlayerTop/>
  <IncHeader/>
  <div class="container">
    <AppImage width="1240" height="165" txt="BANNER TOPO ADS 1240X165PX"/>
    <router-view :key="$route.path"></router-view>
  </div>
  <IncFooter/>
</template>

<script>
import IncHeader from '@/views/partials/inc/IncHeader'
import PlayerTop from '@/views/partials/inc/PlayerTop'
import IncFooter from '@/views/partials/inc/IncFooter'
import AppImage from '@/components/AppImage'


export default {
  name: "App",
  components: {
    IncHeader,
    PlayerTop,
    AppImage,
    IncFooter
  }
}
</script>

<style>
body {
  background-color: #e7e7e7;
}
</style>