<template>
  <div class="container-fluid bg-dark">
    <footer class="py-5 container">
      <div class="d-flex justify-content-between py-4 my-4 border-top">
        <p>&copy; 2022 Jornal do Rádio</p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3"><a class="link-dark" href="#"><b-icon-twitter /></a></li>
          <li class="ms-3"><a class="link-dark" href="#"><b-icon-instagram /></a></li>
          <li class="ms-3"><a class="link-dark" href="#"><b-icon-facebook /></a></li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "IncFooter",
}
</script>

<style scoped>
footer {
  color: white;
}
svg {
  color: white;
}
</style>