<template>
    <iframe src="https://player.jornaldoradio.com/player-barra/6672/000000" frameborder="0" width="100%"></iframe>
</template>

<script>
export default {
  name: "PlayerTop"
}
</script>

<style scoped>
iframe {
  position: fixed;
  width: 100vw;
  height: 31px;
  top: 0px;
  z-index: 1000;
}
</style>