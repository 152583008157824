<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <router-link class="navbar-brand" :to="{name: 'home'}"><img src="https://gospelhd.com.br/admin/assets/img/logo.png" /></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
          <router-link class="nav-link" aria-current="page" :to="{name: 'home'}">INICIO</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" :to="{name: 'about'}">QUEM SOMOS</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" :to="{name: 'news'}">NOTÍCIAS</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" :to="{name: 'programming'}">PROGRAMAÇÃO</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" :to="{name: 'contact'}">CONTATO</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</template>
<script>
export default {
  name: "IncHeader",
  mounted() {
    console.log(this.$route.params)
  }
}

</script>

<style scoped>
nav {
  margin-top: 31px;
  height: auto;
}
li{
  font-size: 18px;
  margin-left: 10px;
}
.navbar-nav > li > a {
  font-family: Oswald, sans-serif;
  overflow: hidden;
  outline: none !important;
  color: #fff !important;
}
.navbar-nav > li > a:hover{
  color: #8f8f8f !important
}

</style>