import {createApp} from 'vue'
import axios from 'axios'
import VueAxios from "vue-axios"
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import {BootstrapIconsPlugin} from 'bootstrap-icons-vue'

axios.defaults.baseURL = process.env.VUE_APP_API_APP01

// import { Notifier } from '@airbrake/browser'

// var airbrake = new Notifier({
//     projectId: 1,
//     projectKey: 'FIXME'
// })

const app = createApp(App);
app.use(VueAxios, axios)
app.use(BootstrapIconsPlugin)
app.use(router)
// app.config.errorHandler = function (err, vm, info) {
//     airbrake.notify({
//         error: err,
//         params: {info: info}
//     });
// }
app.mount('#app')
