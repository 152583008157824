import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView')
  },
  {
    path: '/quem-somos',
    name: 'about',
    component: () => import('../views/AboutView')
  },
  {
    path: '/noticias',
    name: 'news',
    component: () => import('../views/NewsView')
  },
  {
    path: '/noticia/:id/:slug',
    name: 'page-news',
    component: () => import('../views/NewsSingleView'),
    props: true
  },
  {
    path: '/programacao',
    name: 'programming',
    component: () => import('../views/ProgrammingView')
  },
  {
    path: '/contato',
    name: 'contact',
    component: () => import('../views/ContactView')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.APP_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0)
  }
})
export default router
